<template>
  <div id="app" data-app>
    <Main />
    <Modal />
    <QrModal v-if="getQRIsOpen" />
    <Notification />
  </div>
</template>

<script>
import Main from '@/components/Layout/Main';
import Modal from '@/elements/Modals/Modal.vue';
import QrModal from '@/elements/Modals/QrModal.vue';
import Notification from '@/elements/Notification.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Main,
    Modal,
    QrModal,
    Notification,
  },
  data: () => ({
    downloadAppScreen: false,
  }),
  mounted() {
    window.addEventListener('resize', this.windowListener);
    this.downloadAppScreen =
      document.documentElement.clientWidth < 1200 ? true : false;
  },
  methods: {
    windowListener() {
      this.$store.commit(
        'SET_WINDOW_WIDTH',
        document.documentElement.clientHeight
      );
      this.$store.commit(
        'SET_WINDOW_WIDTH',
        document.documentElement.clientWidth
      );
      this.downloadAppScreen =
        document.documentElement.clientWidth < 1200 ? true : false;
    },
  },
  computed: {
    ...mapGetters(['getQRIsOpen']),
  },
};
</script>
<style lang="sass"></style>
