<template>
  <router-link :to="{ name: 'dashboard' }" class="logo">
    <img src="@/assets/images/logo.svg" alt="logo" />
  </router-link>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.logo
  display: flex
</style>
