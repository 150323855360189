<template>
  <div class="input-box">
    <p v-show="isDollarSign" class="dollar-sign">$</p>
    <input
      :id="id"
      :value="value"
      :type="fieldType"
      :placeholder="placeholder"
      :name="name"
      :min="min"
      :required="required"
      class="input"
      autocomplete="off"
      :disabled="isDisabled"
      :class="{
        error: error,
        disabled: isDisabled,
        password: isPassword,
        'dollar-sign__input': isDollarSign,
        wallet: connectWalletBtn,
      }"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @change="$emit('change', $event)"
      @keypress="maskHandler"
      @input="onInput"
    />
    <button
      v-if="connectWalletBtn !== ''"
      class="wallet-btn"
      @click="connectWallet"
    >
      <img
        :src="`/images/${connectWalletBtn}.svg`"
        alt=""
        class="wallet-icon"
      />
    </button>
    <button
      v-if="isPassword"
      type="button"
      class="password-switcher"
      @click="togglePassword"
    >
      <svg-icon :name="passwordSwitcherIcon"></svg-icon>
    </button>
    <div v-if="isPassword" class="password-strength-meter">
      <div
        v-for="item in 9"
        :key="item"
        class="password-strength-meter__item"
        :class="{
          alert: [1, 2, 3].includes(item) && value !== '',
          warning:
            [1, 2, 3, 4, 5, 6].includes(item) &&
            passwordStrength.minLength &&
            (passwordStrength.isHasUppercaseLetter ||
              passwordStrength.isHasNumber),
          success:
            passwordStrength.minLength &&
            passwordStrength.isHasUppercaseLetter &&
            passwordStrength.isHasNumber,
        }"
      ></div>
    </div>
    <InputError v-if="error" :error-txt="errorTxt" />
    <div
      v-if="tooltipText"
      v-tooltip.right="{
        content: tooltipText,
        offset: 6,
      }"
      class="input-box__info"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99984 1.33317C4.33317 1.33317 1.33317 4.33317 1.33317 7.99984C1.33317 11.6665 4.33317 14.6665 7.99984 14.6665C11.6665 14.6665 14.6665 11.6665 14.6665 7.99984C14.6665 4.33317 11.6665 1.33317 7.99984 1.33317Z"
          stroke="#969694"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 10.6665L8 7.33317"
          stroke="#969694"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00391 5.3335L7.99754 5.3335"
          stroke="#969694"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import InputError from '@/elements/InputError.vue';

export default {
  components: {
    InputError,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    min: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    valueMask: {
      type: String,
      default: '',
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    passwordStrength: {
      type: Object,
      default: () => {},
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDollarSign: {
      type: Boolean,
      default: false,
    },
    connectWalletBtn: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowPassword: false,
    };
  },
  computed: {
    fieldType() {
      return this.isPassword ? this.passwordFieldType : this.type;
    },
    passwordFieldType() {
      return this.isShowPassword ? 'text' : 'password';
    },
    passwordSwitcherIcon() {
      return this.isShowPassword ? 'visible-password' : 'hidden-password';
    },
  },
  methods: {
    closeEyes() {
      this.isShowPassword = false;
    },
    maskHandler(event) {
      let char = String.fromCharCode(event.keyCode); // Get the character
      switch (this.valueMask) {
        case 'latin':
          if (!/^[A-Za-z]+$/.test(char)) event.preventDefault();
          // this.$emit('setError()', 'Field must contain only latin characters');
          // this.error = true;
          // this.errorTxt = 'Field must contain only latin characters';
          break;
        case 'latin-and-numbers':
          if (!/^[A-Za-z0-9]+$/.test(char)) {
            // event.preventDefault();
            // this.error = true;
            // this.errorTxt =
            //   'Field must contain only latin characters and numbers';
            this.$store.commit('SET_ERRORS', {
              [this.name]:
                'Field must contain only latin characters and numbers',
            });
          } else {
            this.$store.commit('RESET_FIELD_ERROR', this.name);
          }
          break;
        case 'positive-number':
          if (!/^[0-9.]+$/.test(char)) event.preventDefault();
          break;
        case 'positive-number-integer':
          if (!/^[0-9]+$/.test(char)) event.preventDefault();
          break;
        default:
          break;
      }
    },
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    togglePassword() {
      this.isShowPassword = !this.isShowPassword;
    },
    connectWallet() {
      this.$emit(`connect-${this.connectWalletBtn}-wallet`);
    },
  },
};
</script>

<style lang="sass" scoped>
.input-box
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  position: relative

.dollar-sign
  position: absolute
  top: 50%
  left: 1.8rem
  transform: translate(0, -50%)

.input-box__info
  display: flex
  align-items: center
  position: absolute
  top: -24px
  right: 0
  cursor: pointer

.input-box__info:hover
  svg path
    stroke: $primary-color-blue

.input
  background-color: rgba(255, 255, 255, 0.2)
  border-radius: 4px
  border: 1px solid transparent
  color: $white
  font-size: 12px
  line-height: 18px
  font-weight: 600
  width: inherit
  height: 44px
  padding: 12px 18px
  opacity: 1
  transition: $transition-speed

.input.wallet
  padding: 12px 48px 12px 18px

.dollar-sign__input
  padding-left: 3.2rem

.input:hover
  border: 1px solid $white

.input:focus
  border: 1px solid $primary-color-blue
//
.disabled
  // background: $background-color
  border: none
  cursor: default
  &:hover
    border: none
//.input::-webkit-input-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input:-moz-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input::-moz-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input:-ms-input-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input::-ms-input-placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal
//
//.input::placeholder
//  color: var( --primary-color-dark-grey-1)
//  font-weight: normal

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active
  transition: background-color 5000s ease-in-out 0s
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important
  -webkit-text-fill-color: $white !important

.input.error
  border: 1px solid $negative-text

.input.password
  padding-right: 70px

.password-switcher
  position: absolute
  top: 11px
  right: 12px
  display: flex

.password-strength-meter
  display: grid
  grid-template-columns: repeat(9, 1fr)
  gap: 3px
  width: 100%
  height: 6px
  margin-top: 8px

.password-strength-meter__item
  background-color: rgba(242, 242, 242, 0.2)

.password-strength-meter__item.alert
  background-color: $negative-text

.password-strength-meter__item.warning
  background-color: $warning-color

.password-strength-meter__item.success
  background-color: $success-text

.password-strength-meter__item:first-child
  border-top-left-radius: 2px
  border-bottom-left-radius: 2px

.password-strength-meter__item:last-child
  border-top-right-radius: 2px
  border-bottom-right-radius: 2px

//.input[type='number']
//  -moz-appearance: textfield

.wallet-btn
  position: absolute
  top: 50%
  right: 14px
  transform: translateY(-50%)
  display: flex

  .wallet-icon
    max-width: 22px
    max-height: 22px
</style>
