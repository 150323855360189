<template>
  <div class="loader">
    <div class="loader__block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block; shape-rendering: auto"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          r="32"
          stroke-width="8"
          stroke="#ffffff"
          stroke-dasharray="50.26548245743669 50.26548245743669"
          fill="none"
          stroke-linecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
          ></animateTransform>
        </circle>
        <!-- [ldio] generated by https://loading.io/ -->
      </svg>
      <h1 class="loader-title">Loading</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.loader
  padding: 2.4rem
  display: flex
  flex-direction: column
  gap: 2.4rem
  flex: 0 0 100%
  z-index: 3
  @include m
    padding: 0
    gap: 0

.loader__block
  display: flex
  background-color: $background-profile-color
  border-radius: 8px
  padding: 6.4rem
  flex-direction: column
  align-items: center
  justify-content: space-between
  @include m
    flex-wrap: wrap
    flex-direction: column
  & svg
    max-width: 15.2rem
    max-height: 15.2rem
    margin-bottom: 3.2rem
  @include ms
    background-color: transparent
    padding: 1.6rem
    & svg
      max-width: 10rem
      max-height: 10rem
      margin-top: 10rem

.loader-title
  margin-top: 1rem
  margin-bottom: 6.4rem
  text-align: center
  @include ms
    margin-bottom: 3.2rem
</style>
