<template>
  <button
    :class="cssClasses"
    class="btn"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <svg
      v-if="loading"
      class="loader"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#ffffff"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // color: {
    //   type: String,
    //   required: true,
    // },
    size: {
      type: String,
      default: 'popular',
    },
  },
  computed: {
    cssClasses() {
      return {
        'disabled-btn': this.disabled,
        'transparent-btn': this.transparent,
        popular: this.size === 'popular',
        max: this.size === 'max',
        gray: this.gray,
      };
    },
  },
};
</script>

<style lang="sass">
.btn
  display: flex
  position: relative
  align-items: center
  justify-content: center
  padding: 12px 32px
  background-color: $primary-color-blue
  border-radius: 4px
  font-weight: 600
  font-size: 14px
  line-height: 21px
  color: $white
  cursor: pointer
  transition: $transition-speed
  min-width: 13.2rem
  @include m
    padding: 12px 18.5px

  &:hover
    background-color: $primary-color-blue-hover

  &:active
    background-color: $primary-color-blue-focus

.btn.max
  width: 100%

.loader
  margin-right: 8px

.disabled-btn
  background-color: #1E6194
  cursor: default
  color: #919196

.gray
  background-color: $border-grey-color
  color: $black

.transparent-btn
  background-color: transparent
  border: 1px solid $primary-color-blue
  &:hover
    background-color: $primary-color-blue
</style>
