<template>
  <div class="footer">
    <div class="footer__title text">{{ currentPage.title }}</div>
    <router-link class="footer__link text" :to="currentPage.targetLink">
      {{ currentPage.linkName }}
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pages: {
        auth: {
          title: 'Don’t have an account yet?',
          linkName: 'Sign up now',
          targetLink: { name: 'signup' },
        },
        signup: {
          title: 'Already a member?',
          linkName: 'Sign in',
          targetLink: { name: 'auth' },
        },
      },
    };
  },
  computed: {
    currentPage() {
      return this.pages[this.$route.name];
    },
  },
};
</script>

<style lang="sass" scoped>
.footer
  text-align: center
  padding-top: 48px
  padding-bottom: 76px
  @include ms
    padding-top: 0
    padding-bottom: 40px

.footer__title
  color: $white
  margin-bottom: 4px
  @include ms
    margin-bottom: 0

.footer__link
  color: $primary-color-blue
  font-weight: 600

.footer__title,
.footer__link
  font-size: 14px
  line-height: 21px
</style>
