<template>
  <div class="view auth-view">
    <Header />
    <Content />
    <Footer v-if="isShowFooter" />
  </div>
</template>

<script>
import Header from '@/components/Layout/Header';
import Content from '@/components/Layout/Content';
import Footer from '@/components/Layout/Footer';

export default {
  components: {
    Header,
    Content,
    Footer,
  },
  computed: {
    isShowFooter() {
      return this.$route.name === 'auth' || this.$route.name === 'signup';
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-view
  flex-direction: column
  background-color: $black
  background-image: url(/images/bg.png)
  background-size: cover
  background-position: center
</style>
