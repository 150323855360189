import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import vClickOutside from 'v-click-outside';
import TextField from '@/elements/TextField.vue';
import ButtonBase from '@/elements/ButtonBase.vue';
import SvgIcon from '@/elements/SvgIcon.vue';
import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';
import vueAwesomeCountdown from 'vue-awesome-countdown';

import '@/assets/sass/main.sass';

Vue.component('ButtonBase', ButtonBase);
Vue.component('TextField', TextField);
Vue.component('svg-icon', SvgIcon);

Vue.use(vueAwesomeCountdown, 'vac');
Vue.use(Vuelidate);
Vue.use(vClickOutside);
Vue.use(VTooltip, {
  defaultTrigger: 'hover focus click',
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
