import jwt_decode from 'jwt-decode';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import VueI18n from '@/i18n';

export const checkUserToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (token) {
    const decoded = jwt_decode(token);
    return Date.now() >= decoded.exp * 1000 ? false : token;
  }

  return false;
};

const authInterceptor = (config) => {
  const token = checkUserToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    store.commit('LOGOUT_USER');
  }

  return config;
};

// console.log('>>> env', process.env);

const config = {
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 60 * 1000, // Timeout
};

const Axios = axios.create(config);

// const errorComposer = (NotFound) => {
//   return () => {
//     const statusCode = NotFound.response ? NotFound.response.status : null;

//     if (
//       statusCode === 500 &&
//       NotFound.response.data.message === 'Unauthenticated.'
//     ) {
//       store.commit('LOGOUT_USER');
//       router.push({ name: 'Auth' });
//       return;
//     }
//     if (statusCode) {
//       store.commit('setNotification', {
//         type: 'danger',
//         title: `${VueI18n.t('notification.NotFound')} ${statusCode}`,
//         text: `${VueI18n.t('notification.error_text')}`,
//       });
//       return;
//     }
//     store.commit('setNotification', {
//       type: 'danger',
//       title: `${VueI18n.t('notification.unknown_error')}`,
//       text: `${VueI18n.t('notification.error_text')}`,
//     });
//   };
// };

Axios.interceptors.request.use(authInterceptor);

// Axios.interceptors.response.use(
//   (response) => response,
//   (NotFound) => {
//     NotFound.handleGlobally = errorComposer(NotFound);
//     return Promise.reject(NotFound);
//   }
// );

export { Axios };
