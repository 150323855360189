<template>
  <div class="main">
    <component :is="currentView" v-if="currentRoute"></component>
  </div>
</template>

<script>
import AuthView from '@/views/AuthView';
import ProfileView from '@/views/ProfileView';

export default {
  components: {
    AuthView,
    ProfileView,
  },
  computed: {
    currentRoute() {
      return this.$route.meta.view;
    },
    currentView() {
      return `${this.currentRoute}-view`;
    },
  },
};
</script>

<style lang="sass" scoped></style>
