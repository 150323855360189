import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import modal from './modules/modal';

import {
  login,
  signup,
  signupVerify,
  signupResendVerify,
  resetPassword,
  resetPasswordVerify,
  resetPasswordConfirm,
  changePassword,
  sendEmailVerificationCode,
  getProfile,
  getReferralQR,
  getReferrals,
  getRankingLevels,
  editProfile,
  editWallets,
  updateWalletBalance,
  deletePhoto,
  uploadPhoto,
  getChallengeFeed,
  getChallengeTop,
  likePhoto,
  removeLike,
  getCharityOptions,
  placeOrder,
  getOrder,
  getActivePendingOrder,
  cancelPendingOrder,
} from '@/api/services';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { modal },

  state: {
    user: isLSHasItem('user') ? getFromLS('user') : null,
    token: isLSHasItem('token') ? getFromLS('token') : null,
    resetPasswordData: null,
    accountFrozenState: false,
    menuIsOpen: false,
    errors: {},
    profile: null,
    rankingLevels: null,
    referralQR: null,
    referrals: [],
    notification: null,
    authNotification: null,
    windowWidth: document.documentElement.clientWidth,
    windowHeight: document.documentElement.clientHeight,
    qrIsOpen: false,
    challengeFeed: [],
    challengeTop: null,
    activePendingOrder: null,
    challengeFeedLength: 0,
  },
  getters: {
    isAuthUser: (state) => !!state.token,
    getToken: (state) => state.token,
    getCurrentUser: (state) => state.user,
    getResetPasswordData: (state) => state.resetPasswordData,
    getMenuIsOpen: (state) => state.menuIsOpen,
    getProfile: (state) => state.profile,
    getRankingLevels: (state) => state.rankingLevels,
    getReferralQR: (state) => state.referralQR,
    getReferrals: (state) => state.referrals,
    getErrors: (state) => state.errors,
    getNotification: (state) => state.notification,
    getAuthNotification: (state) => state.authNotification,
    getWindowWidth: (state) => state.windowWidth,
    getWindowHeight: (state) => state.windowHeight,
    getQRIsOpen: (state) => state.qrIsOpen,
    getChallengeFeed: (state) => state.challengeFeed,
    getChallengeTop: (state) => state.challengeTop,
    getActivePendingOrder: (state) => state.activePendingOrder,
    getChallengeFeedLength: (state) => state.challengeFeedLength,
  },
  mutations: {
    SAVE_USER(state, payload) {
      const { token, user } = payload;
      state.user = user;
      state.token = token;
      setToLS('user', user);
      setToLS('token', token);
    },
    UPDATE_USER(state, user) {
      state.user = user;
    },
    LOGOUT_USER(state) {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
    SET_PASSWORD_DATA(state, payload) {
      state.resetPasswordData = payload;
    },
    RESET_PASSWORD_DATA(state) {
      state.resetPasswordData = null;
    },
    SET_MENU_STATE(state, payload) {
      state.menuIsOpen = payload;
    },
    SET_WINDOW_WIDTH(state, options) {
      state.windowWidth = options;
    },
    SET_WINDOW_HEIGHT(state, options) {
      state.windowHeight = options;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    CLEAR_REFERRALS(state) {
      state.referrals = [];
    },
    SET_RANKING_LEVELS(state, payload) {
      state.rankingLevels = payload;
    },
    SET_REFERRAL_QR(state, payload) {
      state.referralQR = payload;
    },
    SET_REFERRALS(state, payload) {
      state.referrals = state.referrals.concat(payload);
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    RESET_ERRORS(state) {
      state.errors = {};
    },
    RESET_FIELD_ERROR(state, fieldName) {
      state.errors[fieldName] = null;
    },
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
    },
    CLEAR_NOTIFICATION(state) {
      state.notification = null;
    },
    SET_AUTH_NOTIFICATION(state, notification) {
      state.authNotification = notification;
    },
    CLEAR_AUTH_NOTIFICATION(state) {
      state.authNotification = null;
    },
    SET_QR_IS_OPEN(state, payload) {
      state.qrIsOpen = payload;
    },
    SET_CHALLENGE_FEED(state, payload) {
      state.challengeFeed = state.challengeFeed.concat(payload);
    },
    SET_CHALLENGE_TOP(state, payload) {
      state.challengeTop = payload;
    },
    CLEAR_CHALLENGE_FEED(state) {
      state.challengeFeed = [];
    },
    SET_ACTIVE_PENDING_ORDER(state, payload) {
      state.activePendingOrder = payload;
    },
    SET_CHALLENGE_FEED_LENGTH(state, payload) {
      state.challengeFeedLength = payload;
    },
  },
  actions: {
    async authorization({ commit, getters, mutations }, data) {
      let res;
      try {
        res = await login(data);
        const { token, user } = res.data.data;
        commit('SAVE_USER', {
          token,
          user,
        });

        router.push({ name: 'dashboard' });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async signup({ commit }, data) {
      let res;
      try {
        res = await signup(data);
        router.push({
          name: 'signup-verify',
          query: { email: data.email },
        });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async signupVerify({ commit }, data) {
      let res;
      try {
        res = await signupVerify(data);
        router.push({ name: 'signup-success' });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async signupResendVerify({ commit, dispatch }, data) {
      let res;
      try {
        res = await signupResendVerify(data);
        if (res.status === 200) {
          dispatch('setAuthNotification', {
            text: 'We sent you code again',
          });
        }
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async resetPassword({ commit, dispatch }, data) {
      let res;
      try {
        res = await resetPassword(data);
        if (router.currentRoute.name === 'forgot-password') {
          router.push({
            name: 'forgot-password-verify',
            query: {
              email: data.email,
            },
          });
        } else {
          dispatch('setAuthNotification', {
            text: 'We sent you another code',
          });
        }
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async resetPasswordVerify({ commit }, data) {
      let res;
      try {
        res = await resetPasswordVerify(data);
        if (res.data.data.is_valid_code) {
          commit('SET_PASSWORD_DATA', {
            email: data.email,
            code: data.code,
          });
          router.push({
            name: 'change-password',
          });
        }
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async resetPasswordConfirm({ commit, dispatch }, data) {
      let res;
      try {
        res = await resetPasswordConfirm(data);
        commit('RESET_PASSWORD_DATA');
        router.push({
          name: 'auth',
          params: {
            email: data.email,
          },
        });
        dispatch('setAuthNotification', {
          text: 'Password changed successfully',
        });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async changePassword({ getters, commit, dispatch }, data) {
      let res;
      try {
        res = await changePassword(data);
        dispatch('setNotification', {
          type: 'success',
          title: 'New password',
          text: 'Password changed successfully',
        });
        return res.status === 200;
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async editProfile({ getters, commit, dispatch }, data) {
      let res;
      try {
        res = await editProfile(data);

        if (res.status === 200) {
          commit('SET_PROFILE', res.data.data.user);
          dispatch('setNotification', {
            type: 'success',
            title: 'Success!',
            text: 'Profile edited successfully',
          });
        }

        return res.status === 200;
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async editWallets({ getters, commit, dispatch }, data) {
      let res;
      try {
        res = await editWallets(data);

        if (res.status === 200) {
          commit('SET_PROFILE', res.data.data.user);
          dispatch('setNotification', {
            type: 'success',
            title: 'Success!',
            text: 'Profile edited successfully',
          });
        }
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async updateWalletBalance({ commit, dispatch }, name) {
      let res;
      try {
        res = await updateWalletBalance(name);
        if (res.status === 200) commit('SET_PROFILE', res.data.data.user);
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async deletePhoto({ dispatch }) {
      let res;
      try {
        res = await deletePhoto();
        if (res.status === 200) {
          dispatch('setNotification', {
            type: 'success',
            title: 'Success!',
            text: 'Photo deleted successfully',
          });
          await dispatch('getProfile');
        }
      } catch (e) {
        console.log('>>> deletePhoto error', e);
      }
    },
    async uploadPhoto({ dispatch }, data) {
      let res;
      try {
        res = await uploadPhoto(data);
        if (res.status === 200) {
          dispatch('setNotification', {
            type: 'success',
            title: 'Success!',
            text: 'Photo uploaded successfully',
          });
          await dispatch('getProfile');
        }
      } catch (e) {
        console.log('>>> deletePhoto error', e);
      }
    },
    async getProfile({ commit }) {
      let res;
      try {
        res = await getProfile();
        commit('SET_PROFILE', res.data.data.user);
      } catch (e) {
        console.log('>>> getProfile error', e.response.data.status);
      }
    },
    async getReferralQR({ commit }) {
      let res;
      try {
        res = await getReferralQR();
        commit('SET_REFERRAL_QR', res.data.data.qr);
      } catch (e) {
        console.log('>>> getReferralQR error', e);
      }
    },
    async getReferrals({ commit }, data) {
      let res;
      try {
        res = await getReferrals(data);
        commit('SET_REFERRALS', res.data.data.referrals);
      } catch (e) {
        console.log('>>> getReferrals error', e);
      }
    },
    async getRankingLevels({ commit }) {
      let res;
      try {
        res = await getRankingLevels();
        commit('SET_RANKING_LEVELS', res.data.data.levels);
      } catch (e) {
        console.log('>>> getRankingLevels error', e);
      }
    },
    async sendEmailVerificationCode({ commit }) {
      let res;
      try {
        res = await sendEmailVerificationCode();
      } catch (e) {
        console.log(
          '>>> sendEmailVerificationCode error',
          e.response.data.status
        );
      }
    },
    async getChallengeFeed({ commit }, data) {
      let res;
      try {
        res = await getChallengeFeed(data);
        commit('SET_CHALLENGE_FEED', res.data.data.photos);
        commit('SET_CHALLENGE_FEED_LENGTH', res.data.data.total_count);
      } catch (e) {
        console.log('>>> getChallengeFeed error', e);
      }
    },
    async getChallengeTop({ commit }) {
      let res;
      try {
        res = await getChallengeTop();
        commit('SET_CHALLENGE_TOP', res.data.data.photos);
      } catch (e) {
        console.log('>>> getChallengeTop error', e);
      }
    },
    async likePhoto({ commit, dispatch }, data) {
      try {
        await likePhoto(data);
        dispatch('sortPhotos', { data, like: 'set' });
      } catch (e) {
        console.log('>>> likePhoto error', e);
      }
    },
    async removeLike({ commit, dispatch }, data) {
      try {
        await removeLike(data);
        dispatch('sortPhotos', { data, like: 'unset' });
      } catch (e) {
        console.log('>>> likePhoto error', e);
      }
    },
    async getCharityOptions({ commit }) {
      let res;
      try {
        res = await getCharityOptions();
        return res.data.data.charities;
      } catch (e) {
        console.log('>>> getCharityOptions error', e);
      }
    },
    async placeOrder({ commit }, data) {
      let res;
      try {
        res = await placeOrder(data);
        return res;
      } catch (e) {
        console.log('>>> placeOrder error', e);
      }
    },
    async getOrder({ commit }, data) {
      let res;
      try {
        res = await getOrder(data);
        return res.data.data.order.status;
      } catch (e) {
        console.log('>>> getOrder error', e);
      }
    },
    async getActivePendingOrder({ commit }) {
      let res;
      try {
        res = await getActivePendingOrder();
        commit('SET_ACTIVE_PENDING_ORDER', res.data.data.order);
      } catch (e) {
        console.log('>>> getActivePendingOrder error', e);
      }
    },
    async cancelPendingOrder({ commit }, data) {
      let res;
      try {
        res = await cancelPendingOrder(data);
        console.log(res);
        // return res.data.data.order.status;
      } catch (e) {
        console.log('>>> cancelPendingOrder error', e);
      }
    },
    async sortPhotos({ commit, getters }, data) {
      let sortedPhotos = getters.getChallengeTop.concat(
        getters.getChallengeFeed
      );
      const photoToChange = sortedPhotos.filter(
        (photo) => photo.uuid === data.data
      )[0];
      data.like === 'set'
        ? (photoToChange.is_liked = true)
        : (photoToChange.is_liked = false);
      data.like === 'set'
        ? photoToChange.likes_count++
        : photoToChange.likes_count--;
      sortedPhotos.sort((a, b) => b.likes_count - a.likes_count);
      // let profileToUpdate = getters.getProfile;
      // data.like === 'set'
      //   ? profileToUpdate.challenge_daily_remaining_likes--
      //   : profileToUpdate.challenge_daily_remaining_likes++;
      // commit('SET_PROFILE', profileToUpdate);
      commit('SET_CHALLENGE_TOP', sortedPhotos.slice(0, 10));
      commit('CLEAR_CHALLENGE_FEED');
      commit('SET_CHALLENGE_FEED', sortedPhotos.slice(10));
    },
    setMenuState({ commit }, payload) {
      commit('SET_MENU_STATE', payload);
    },
    setNotification({ commit, getters }, notification) {
      commit('SET_NOTIFICATION', notification);
      setTimeout(() => {
        if (getters.getNotification) {
          commit('CLEAR_NOTIFICATION');
        }
      }, 5000);
    },
    setAuthNotification({ commit, getters }, notification) {
      commit('SET_AUTH_NOTIFICATION', notification);
      setTimeout(() => {
        if (getters.getAuthNotification) {
          commit('CLEAR_AUTH_NOTIFICATION');
        }
      }, 15000);
    },
  },
});
