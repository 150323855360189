<template>
  <div
    :class="{
      hidden: getMenuIsOpen,
      'full-screen': isMobileFullScreen,
    }"
    class="content"
  >
    <Loader v-if="loading" />
    <keep-alive v-else>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/elements/Loader';

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['getMenuIsOpen']),
    isMobileFullScreen() {
      const fullScreenMobilePages = [
        'signup-verify',
        'signup-success',
        'forgot-password',
        'forgot-password-verify',
        'change-password',
        'not-found',
      ];
      return fullScreenMobilePages.some((routeName) =>
        this.$route.name.includes(routeName)
      );
    },
  },
  async created() {
    this.loading = true;
    await Promise.all([
      this.$store.dispatch('getProfile'),
      this.$store.dispatch('getReferralQR'),
      this.$store.dispatch('getReferrals', 0),
      this.$store.dispatch('getRankingLevels'),
      this.$store.dispatch('getChallengeFeed', 10),
      this.$store.dispatch('getChallengeTop'),
      this.$store.dispatch('getActivePendingOrder'),
    ]);
    this.loading = false;
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_REFERRALS');
    this.$store.commit('SET_MENU_STATE', false);
  },
};
</script>

<style lang="sass" scoped>
.hidden
  display: none

.auth-view .content
  display: flex
  align-items: center
  justify-content: center

.auth-view .content.full-screen
  @include ms
    height: calc(100vh - 64px)

.auth-view .content.full-screen::v-deep
  .container
    @include ms
      padding: 0 16px

.profile-view .content
  flex-grow: 1
  margin-left: 24rem
  @include xl
    margin-left: 0
</style>
