<template>
  <div
    :class="{
      hidden: getMenuIsOpen,
      'full-screen': isMobileFullScreen,
    }"
    class="content"
  >
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getMenuIsOpen']),
    isMobileFullScreen() {
      const fullScreenMobilePages = [
        'signup-verify',
        'signup-success',
        'forgot-password',
        'forgot-password-verify',
        'change-password',
        'not-found',
      ];
      return fullScreenMobilePages.some((routeName) =>
        this.$route.name.includes(routeName)
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.hidden
  display: none

.auth-view .content
  display: flex
  align-items: center
  justify-content: center

.auth-view .content.full-screen
  @include ms
    height: calc(100vh - 64px)

.auth-view .content.full-screen::v-deep
  .container
    @include ms
      padding: 0 16px

.profile-view .content
  flex-grow: 1
  margin-left: 24rem
  @include xl
    margin-left: 0
</style>
