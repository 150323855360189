<template>
  <div class="view profile-view" :class="{ 'menu-opened': getMenuIsOpen }">
    <!-- <HeaderMenu class="header-menu" /> -->
    <Sidebar />
    <ContentProfile />
    <!-- <div class="background">
      <svg-icon name="background"></svg-icon>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import HeaderMenu from '@/components/Layout/HeaderMenu';
import Sidebar from '@/components/Layout/Sidebar';
import ContentProfile from '@/components/Layout/ContentProfile';

export default {
  components: {
    Sidebar,
    ContentProfile,
    // HeaderMenu,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getMenuIsOpen']),
  },
};
</script>

<style lang="sass" scoped>
.profile-view
  display: flex
  justify-content: start
  background-color: $black
  background-image: url(/images/bg.png)
  background-size: cover
  background-position: center
  @include xl
    flex-direction: column
    background-image: url(/images/bg_mob.png)
    background-position: top

// .background
//   background: no-repeat url("../assets/icons/background.svg")
//   // & svg
//   background-size: contain
//   position: fixed
//   max-width: 150%
//   max-height: 150%
//   width: 100vw
//   height: 100vh
//   left: -20%
//   z-index: 5

// .header-menu
//   display: none
//   @include m
//     display: block

// .sidebar
//   @include m
//     display: none
</style>
