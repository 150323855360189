<template>
  <header class="header">
    <div class="header__inner">
      <div class="header__logo">
        <MainLogo />
      </div>
    </div>
  </header>
</template>

<script>
import MainLogo from '@/components/Logo/MainLogo.vue';
export default {
  components: {
    MainLogo,
  },
};
</script>

<style lang="sass" scoped>
.header
  padding-top: 64px
  @include l
    padding-top: 94px
  @include ms
    padding-top: 24px

.header__inner
  display: flex
  justify-content: center
</style>
