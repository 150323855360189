<template>
  <div :class="{ 'sidebar-open': getMenuIsOpen }" class="sidebar">
    <div :class="{ 'sidebar__top-open': getMenuIsOpen }" class="sidebar__top">
      <div class="header__logo">
        <MainLogo />
      </div>
      <div class="sidebar__menu-button" @click="toggleMenu()">
        <svg-icon
          v-if="!getMenuIsOpen"
          class="menu__button"
          name="menu_button"
        ></svg-icon>
        <svg-icon
          v-else
          class="menu__button-close"
          name="menu_button_close"
        ></svg-icon>
      </div>
    </div>
    <div :class="{ 'sidebar__main-open': getMenuIsOpen }" class="sidebar__main">
      <img class="logo__image" src="@/assets/images/logo_sidebar.png" />

      <div
        :class="{ 'sidebar__button-active': $route.name === 'dashboard' }"
        class="sidebar__button-dashboard"
        @click="goTo('/dashboard')"
      >
        <img
          class="dashboard__border"
          src="@/assets/images/sidebar_border.png"
          alt=""
        />
        <svg-icon class="dashboard__icon" name="dashboard"></svg-icon>
        <p class="text">Dashboard</p>
      </div>
      <!--      <div-->
      <!--        :class="{ 'sidebar__button-active': $route.name === 'challenge' }"-->
      <!--        class="sidebar__button-challenge"-->
      <!--        @click="goTo('/challenge')"-->
      <!--      >-->
      <!--        <img-->
      <!--          class="dashboard__border"-->
      <!--          src="@/assets/images/sidebar_border.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <svg-icon class="challenge__icon" name="challenge"></svg-icon>-->
      <!--        <p class="text">Photo Challenge</p>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        :class="{ 'sidebar__button-active': $route.name === 'card' }"-->
      <!--        class="sidebar__button-card"-->
      <!--        @click="goTo('/card')"-->
      <!--      >-->
      <!--        <img-->
      <!--          class="dashboard__border"-->
      <!--          src="@/assets/images/sidebar_border.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <svg-icon class="source__icon" name="card-menu"></svg-icon>-->
      <!--        <p class="text">Source Card</p>-->
      <!--      </div>-->
      <div
        :class="{ 'sidebar__button-active': $route.name === 'settings' }"
        class="sidebar__button-settings"
        @click="goTo('/settings')"
      >
        <img
          class="dashboard__border"
          src="@/assets/images/sidebar_border.png"
          alt=""
        />
        <svg-icon class="settings__icon" name="settings"></svg-icon>
        <p class="text">Settings</p>
      </div>
      <ButtonBase class="sidebar__button-logout" @click="logoutHandler">
        Log out
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainLogo from '@/components/Logo/MainLogo.vue';

export default {
  components: { MainLogo },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getMenuIsOpen', 'getWindowWidth', 'getActivePendingOrder']),
    // fullName() {
    //   return (
    //     this.getCurrentUser.first_name + ' ' + this.getCurrentUser.last_name
    //   );
    // },
  },
  watch: {
    getWindowWidth() {
      if (this.getWindowWidth > 767) {
        this.$store.dispatch('setMenuState', false);
      }
    },
  },
  methods: {
    goTo(page) {
      this.$store.dispatch('setMenuState', false);
      if (page === '/card' && this.getActivePendingOrder) {
        this.$router.push({
          name: 'card-status',
          params: { id: this.getActivePendingOrder.uuid },
        });
      } else if (this.$route.path !== page) {
        this.$router.push(page);
      }
    },
    toggleMenu() {
      this.$store.dispatch('setMenuState', !this.getMenuIsOpen);
    },
    logoutHandler() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'log-out',
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.sidebar
  position: fixed
  height: 100vh
  background-color: transparent
  z-index: 2
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.5)
  background: rgba(0, 0, 0, 0.5)
  @include xl
    // position: relative
    height: 8.2rem
    position: relative
    padding: 2.6rem 0
  @include m
    padding: 1.6rem 0
    height: 7.4rem


.sidebar-open
  height: 100%
  flex-grow: 1

.sidebar__top
  position: relative
  width: 24rem
  height: 40rem
  background-repeat: no-repeat
  background-size: 100% 100%
  padding: 2.6rem 4rem
  @include xl
    position: absolute
    top: 0
    left: 0
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    height: 8.2rem
  @include m
    padding: 1.6rem 1.6rem
    height: 7.4rem
// .sidebar__top-open
//   height: 12rem
  // background-color: $white

.sidebar__menu-button
  display: none
  @include xl
    display: flex
    justify-content: center
    align-items: center
    // position: absolute
    // right: 4rem
    // top: 1.6rem
    cursor: pointer

.sidebar__main
  z-index: 10
  display: flex
  align-items: center
  flex-direction: column
  height: calc(100vh - 43rem)
  @include xl
    display: none

.sidebar__username
  margin-top: 5.6rem

.sidebar__main-open
  display: flex
  // background-color: $white
  align-items: center
  flex-direction: column
  height: calc(100vh - 21rem)
  margin-top: 15rem

.sidebar__email
  margin-bottom: 6.4rem

.sidebar__button-dashboard, .sidebar__button-challenge, .sidebar__button-settings, .sidebar__button-card
  display: flex
  align-items: center
  width: 24rem
  gap: 0.8rem
  height: 5.6rem
  width: 100%
  cursor: pointer
  @include xl
    justify-content: center
    gap: 1.2rem
    & p
      font-size: 2.4rem
      line-height: 3.2rem
  & img
    opacity: 0
    margin-right: 2rem
  &:hover
    background: rgba(255, 255, 255, 0.1)
    & img
      opacity: 1

.sidebar__button-active
  background: rgba(255, 255, 255, 0.1)
  & img
      opacity: 1

.sidebar__button-logout
  z-index: 10
  margin: auto 0 0

.logo__top
  z-index: 10
  position: absolute
  left: 0
  right: 0
  top: 2.4rem
  margin: 0 auto
  @include xl
    margin: auto
    top: 1.7rem
    left: 4rem
    margin: unset

.logo__image
  max-height: 18.6rem
  max-width: 11.8rem
  z-index: 10
  position: absolute
  left: 0
  right: 0
  top: 8rem
  margin: 0 auto
  @include xl
    display: none

.logo__logout
  position: absolute
  left: 0
  right: 0
  bottom: 0
  margin-right: auto

.menu__button
  width: 100%
  height: 100%
  width: 5rem
  height: 5rem



.dashboard__icon, .challenge__icon, .settings__icon, .source__icon
  max-width: 3rem
  max-height: 3rem

.header__logo
  display: none
  @include xl
    display: block

.dashboard__border
  @include xl
    display: none

// .security__icon
//   max-width: 2.7rem
//   max-height: 2.7rem
//   & use
//     width: 100%
//     height: 100%
</style>
