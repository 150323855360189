<template>
  <div class="modal-qr">
    <div class="modal-qr__main">
      <div v-html="getReferralQR"></div>
    </div>
    <div class="modal__cross" @click="closeModal">
      <svg-icon name="cross_qr" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getReferralQR', 'getWindowWidth']),
  },
  watch: {
    getWindowWidth() {
      if (this.getWindowWidth > 767) {
        this.closeModal();
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('SET_QR_IS_OPEN', false);
    },
  },
};
</script>

<style lang="sass" scoped>
.modal-qr
  position: fixed
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(255, 255, 255, 0.1)
  backdrop-filter: blur(16px)
  z-index: 10

.modal-qr__main
  position: relative
  height: 100%
  & div
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 15.6rem
    height: 15.6rem
    & svg
      width: 15.6rem
      height: 15.6rem

.modal__cross
  position: fixed
  top: 1.2rem
  right: 1.2rem
  width: 4rem
  height: 4rem
  background: rgba(255, 255, 255, 0.1)
  border-radius: 4px
  cursor: pointer
</style>
