<template>
  <v-dialog
    v-model="getModalState"
    persistent
    :max-width="`${modalWidth}px`"
    @keydown.esc="close"
    @click:outside="close"
  >
    <div class="modal modal__container">
      <div class="modal__container_content">
        <!--        <div class="modal__container_wrapper-cross" @click="close">-->
        <!--          <svg-icon name="cross" />-->
        <!--        </div>-->
        <component
          :is="modalName"
          @go-to-route="closeModalWithRouting"
        ></component>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import LogOutModal from '@/elements/Modals/LogOutModal.vue';
import QrModal from '@/elements/Modals/QrModal.vue';

export default {
  components: {
    LogOutModal,
    QrModal,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getCurrentUser']),
    ...mapGetters('modal', ['getModalState', 'getModalName', 'getModalProps']),
    modalName() {
      return this.getModalName ? `${this.getModalName}-modal` : '';
    },
    modalWidth() {
      switch (this.getModalName) {
        case 'log-out':
          return 640;
        case 'qr':
          return 1024;
        default:
          return 940;
      }
    },
  },

  watch: {
    getModalState(value) {
      if (value) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    },
  },

  methods: {
    close() {
      if (this.getModalName === 'confirm-change-percentage') {
        this.$store.commit('UPDATE_USER', {
          ...this.getCurrentUser,
          compound_percentage: this.getModalProps.percent.old,
        });
      }
      this.$store.commit('modal/SET_MODAL', { state: false });
      this.$store.commit('RESET_ERRORS');
      this.$store.commit('modal/CLEANE_MODAL_HISTORY_ROUTE');
    },
    closeModalWithRouting(path) {
      this.close();
      this.$router.push(path);
      this.$store.commit('modal/CLEANE_MODAL_HISTORY_ROUTE');
    },
  },
};
</script>
<style lang="sass">
.modal__container_wrapper-cross
  position: absolute
  top: 4.8rem
  right: 4.8rem
  display: flex
  border-radius: 50%
  cursor: pointer
  z-index: 2
  @include l
    top: 3.2rem
    right: 3.2rem
  @include m
    top: 0.7rem
    right: 0.7rem
    border-radius: 0
    background: none
  & svg
    max-height: 4.8rem
    max-width: 4.8rem

.modal__container_content
  // background-color: $white
  @include ms
    position: relative

.modal__content
  display: flex
  flex-direction: column
  align-items: center
  padding: 4rem 6.4rem
  background: rgba(6, 6, 17, 0.64)
  backdrop-filter: blur(16px)
  overflow-y: auto
  border-radius: 8px
  // @include ms
  //   min-height: calc(100vh - 62px)
  //   justify-content: center
  //   padding: 2.4rem 1.6rem !important
</style>
