<template>
  <div v-if="getNotification" :class="cssClasses" class="notification">
    <div class="notification__icon">
      <svg-icon :name="getNotification.type"></svg-icon>
    </div>
    <div class="notification__info">
      <div class="notification__title">{{ getNotification.title }}</div>
      <div class="notification__text">{{ getNotification.text }}</div>
    </div>
    <button class="notification__close" @click="close">
      <svg-icon name="cross_black"></svg-icon>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getNotification']),
    cssClasses() {
      return {
        'notification--success': this.getNotification.type === 'success',
        'notification--warning': this.getNotification.type === 'warning',
        'notification--error': this.getNotification.type === 'error',
      };
    },
  },
  methods: {
    close() {
      this.$store.commit('CLEAR_NOTIFICATION');
    },
  },
};
</script>

<style lang="sass" scoped>
.notification
  position: fixed
  top: 20px
  right: 20px
  display: flex
  align-items: center
  gap: 24px
  padding: 18px 45px 18px 18px
  max-width: 320px
  background-color: $white
  border: 1px solid $border-grey-color
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1)
  z-index: 9
  @include ms
    padding: 15px 25px 15px 15px

.notification__icon
  display: flex

.notification__title
  font-size: 1.6rem
  line-height: 1.9rem
  font-weight: 500
  color: $black
  @include ms
    font-size: 1.4rem

.notification__text
  font-size: 1.2rem
  line-height: 2rem
  color: $subtitle-color
  margin-top: 6px
  @include ms
    margin-top: 3px

.notification__close
  position: absolute
  width: 24px
  height: 24px
  top: -12px
  right: -12px
  display: flex
  align-items: center
  justify-content: center
  background-color: $white
  border: 1px solid $border-grey-color
  border-radius: 50%
  cursor: pointer

.icon-success,
.icon-error
  max-width: 54px
  max-height: 54px
  @include ms
    max-width: 40px
    max-height: 40px

.icon-cross_black
  max-width: 7px
  max-height: 7px
</style>
