import { Axios } from '@/api/config';

const api_version = '/api';

// export const getDashboard = () => {
//   const url = `${api_version}/dashboard`;
//   return Axios.get(url);
// };

// export const getDashboardSettings = () => {
//   const url = `${api_version}/dashboard/settings`;
//   return Axios.get(url);
// };

export const getProfile = () => {
  const url = `${api_version}/profile/me`;
  return Axios.get(url);
};

export const getReferralQR = () => {
  const url = `${api_version}/profile/referral-qr`;
  return Axios.get(url);
};

export const getReferrals = (data) => {
  const url = `${api_version}/dashboard/referrals/users?offset=${data}`;
  return Axios.get(url);
};

export const getRankingLevels = () => {
  const url = `${api_version}/dashboard/referrals/ranking-levels`;
  return Axios.get(url);
};

export const sendEmailVerificationCode = () => {
  const url = `${api_version}/profile/security/two-factor/send-email-verification-code`;
  return Axios.post(url);
};

export const login = (data) => {
  const url = `${api_version}/auth/sign-in`;
  return Axios.post(url, data);
};

export const signup = (data) => {
  const url = `${api_version}/auth/sign-up`;
  return Axios.post(url, data);
};

export const signupVerify = (data) => {
  const url = `${api_version}/auth/sign-up/verify`;
  return Axios.post(url, data);
};

export const signupResendVerify = (data) => {
  const url = `${api_version}/auth/sign-up/verify/resend`;
  return Axios.post(url, data);
};

export const resetPassword = (data) => {
  const url = `${api_version}/auth/reset-password`;
  return Axios.post(url, data);
};

export const resetPasswordVerify = (data) => {
  const url = `${api_version}/auth/reset-password/verify`;
  return Axios.post(url, data);
};

export const resetPasswordConfirm = (data) => {
  const url = `${api_version}/auth/reset-password/confirm`;
  return Axios.post(url, data);
};

export const changePassword = (data) => {
  const url = `${api_version}/profile/security/change-password`;
  return Axios.post(url, data);
};

export const editProfile = (data) => {
  const url = `${api_version}/profile/update`;
  return Axios.post(url, data);
};

export const editWallets = (data) => {
  const url = `${api_version}/profile/update-wallet`;
  return Axios.post(url, data);
};

export const updateWalletBalance = (name) => {
  const url = `${api_version}/profile/update-balance-wallet?name=${name}`;
  return Axios.post(url);
};

export const deletePhoto = () => {
  const url = `${api_version}/profile/delete-photo`;
  return Axios.delete(url);
};

export const uploadPhoto = (data) => {
  const url = `${api_version}/profile/change-photo`;
  return Axios.post(url, data);
};

export const getChallengeFeed = (data) => {
  const url = `${api_version}/challenge/photos/feed?offset=${data}`;
  return Axios.get(url, data);
};

export const getChallengeTop = () => {
  const url = `${api_version}/challenge/photos/top`;
  return Axios.get(url);
};

export const likePhoto = (data) => {
  const url = `${api_version}/challenge/photos/${data}/like`;
  return Axios.post(url);
};

export const removeLike = (data) => {
  const url = `${api_version}/challenge/photos/${data}/remove-like`;
  return Axios.delete(url);
};

export const getCharityOptions = () => {
  const url = `${api_version}/card/charities`;
  return Axios.get(url);
};

export const placeOrder = (data) => {
  const url = `${api_version}/card/order/place`;
  return Axios.post(url, data);
};

export const getOrder = (data) => {
  const url = `${api_version}/card/order/${data}`;
  return Axios.get(url);
};

export const getActivePendingOrder = () => {
  const url = `${api_version}/card/order/active-pending-order`;
  return Axios.get(url);
};

export const cancelPendingOrder = (data) => {
  const url = `${api_version}/card/order/${data}/cancel`;
  return Axios.post(url);
};
