<template>
  <div class="modal__log-out">
    <div class="modal__content">
      <h1 class="modal__title">Log out</h1>
      <div class="modal__text text">
        Are you sure you want to leave and log out?
      </div>
      <div class="modal__control">
        <ButtonBase class="modal__button" transparent @click="closeModal">
          Cancel
        </ButtonBase>
        <ButtonBase class="modal__button" @click="logOut">Yes</ButtonBase>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      logOutLoader: false,
    };
  },
  computed: {
    ...mapGetters(['getWindowWidth']),
  },
  methods: {
    logOut() {
      this.$store.commit('LOGOUT_USER');
      this.$store.commit('modal/SET_MODAL', {
        state: false,
      });
      if (this.getWindowWidth <= 1256)
        this.$store.dispatch('setMenuState', false);

      this.$router.push({ name: 'auth' });
    },
    closeModal() {
      this.$store.commit('modal/SET_MODAL', {
        state: false,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__control
  display: flex
  gap: 3.2rem
  width: 100%
  & button
    flex: 1 0 calc(50% - 1.6rem)
  @include m
    flex-direction: column
    gap: 1.6rem

.modal__content
  align-items: center
  padding: 6.4rem
  text-align: center
  @include m
    padding: 6.4rem 1.6rem

.modal__text
  margin: 1.2rem auto 4rem

.modal__title
  @include m
    font-weight: 400
</style>
