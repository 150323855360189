var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-box"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDollarSign),expression:"isDollarSign"}],staticClass:"dollar-sign"},[_vm._v("$")]),_c('input',{staticClass:"input",class:{
      error: _vm.error,
      disabled: _vm.isDisabled,
      password: _vm.isPassword,
      'dollar-sign__input': _vm.isDollarSign,
      wallet: _vm.connectWalletBtn,
    },attrs:{"id":_vm.id,"type":_vm.fieldType,"placeholder":_vm.placeholder,"name":_vm.name,"min":_vm.min,"required":_vm.required,"autocomplete":"off","disabled":_vm.isDisabled},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){return _vm.$emit('change', $event)},"keypress":_vm.maskHandler,"input":_vm.onInput}}),(_vm.connectWalletBtn !== '')?_c('button',{staticClass:"wallet-btn",on:{"click":_vm.connectWallet}},[_c('img',{staticClass:"wallet-icon",attrs:{"src":("/images/" + _vm.connectWalletBtn + ".svg"),"alt":""}})]):_vm._e(),(_vm.isPassword)?_c('button',{staticClass:"password-switcher",attrs:{"type":"button"},on:{"click":_vm.togglePassword}},[_c('svg-icon',{attrs:{"name":_vm.passwordSwitcherIcon}})],1):_vm._e(),(_vm.isPassword)?_c('div',{staticClass:"password-strength-meter"},_vm._l((9),function(item){return _c('div',{key:item,staticClass:"password-strength-meter__item",class:{
        alert: [1, 2, 3].includes(item) && _vm.value !== '',
        warning:
          [1, 2, 3, 4, 5, 6].includes(item) &&
          _vm.passwordStrength.minLength &&
          (_vm.passwordStrength.isHasUppercaseLetter ||
            _vm.passwordStrength.isHasNumber),
        success:
          _vm.passwordStrength.minLength &&
          _vm.passwordStrength.isHasUppercaseLetter &&
          _vm.passwordStrength.isHasNumber,
      }})}),0):_vm._e(),(_vm.error)?_c('InputError',{attrs:{"error-txt":_vm.errorTxt}}):_vm._e(),(_vm.tooltipText)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
      content: _vm.tooltipText,
      offset: 6,
    }),expression:"{\n      content: tooltipText,\n      offset: 6,\n    }",modifiers:{"right":true}}],staticClass:"input-box__info"},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.99984 1.33317C4.33317 1.33317 1.33317 4.33317 1.33317 7.99984C1.33317 11.6665 4.33317 14.6665 7.99984 14.6665C11.6665 14.6665 14.6665 11.6665 14.6665 7.99984C14.6665 4.33317 11.6665 1.33317 7.99984 1.33317Z","stroke":"#969694","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M8 10.6665L8 7.33317","stroke":"#969694","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M8.00391 5.3335L7.99754 5.3335","stroke":"#969694","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }